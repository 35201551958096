@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0px;
  font-family: Railway;
  padding: 0%;
  background-color: #F1F3FF;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.aling-center{
  align-items: center;
}

.flex-evenly{
  justify-content: space-evenly;
}

.flex-between {
  justify-content: space-between;
  align-items: center;
}
.flex-column{
  flex-direction: column;
}

.flex-wrap{
  flex-wrap: wrap;
}

.h2 {
  font-size: x-large;
  font-weight: bolder;
}

.h1 {
  font-weight: bolder;
  font-size: 2.924vw;
}

.h4{
  font-weight: bold;
  font-size: 1.17vw;
}

.h3{
  font-weight: bolder;
  font-size: 1.462vw;
}

.h6 {
  font-size: large;
  font-weight: 35.088vw;
}

.text-center{
  text-align: center;
}

.gray-font{
  color: #7B7A8B
}

.secondary-color{
  color: #C88F13
}

.focus-color{
  color: #F95C19
}

.mainContainer{
  margin-top: 10.058vw;
}
